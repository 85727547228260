'use client';

import React from 'react';
import {
  Image,
  Navbar,
  NavbarItem,
  NavbarContent,
  NavbarMenuToggle,
  NavbarMenuItem,
  NavbarMenu,
  Button,
} from '@nextui-org/react';
import { Link } from '@/navigation';
import { useTranslations } from 'next-intl';
import { LocaleProps } from '@/typings/next-page';
import { headerContainer, headerLinks } from './style';
import { useNavigation } from '@/hooks/use-navigation';
import LocaleSwitcherSelect from '@/components/locale-switcher';
import View from '@/components/view';

const Header = ({ locale }: LocaleProps['params']) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const t = useTranslations('header.links');
  useNavigation({
    on: {
      routeChanged() {
        setIsMenuOpen(false);
      },
    },
  });

  const menuItems = [
    { link: t('home.href'), name: t('home.name') },
    { link: t('projects.href'), name: t('projects.name') },
    {
      link: t('services.href'),
      name: t('services.name'),
    },
    { link: t('about.href'), name: t('about.name') },
    { link: t('news.href'), name: t('news.name') },
    {
      link: t('contact.href'),
      name: t('contact.name'),
    },
  ];

  return (
    <>
      <Navbar
        isBlurred={false}
        isBordered
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
        className={headerContainer({})}
        classNames={{
          wrapper: 'max-w-none section ',
        }}
      >
        <NavbarContent className="flex items-center justify-between">
          <li className="flex-1">
            <Link href={'/'} className="z-10 relative flex-1">
              <Image
                alt="logo"
                radius="none"
                loading="eager"
                src={'/logo-gc.svg'}
                className="min-h-14 min-w-14 sm:min-h-20 sm:min-w-24"
              />
            </Link>
          </li>
          <li className="flex h-full">
            <NavbarMenuToggle
              className="sm:hidden"
              aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            />
          </li>
        </NavbarContent>
        <NavbarContent
          className="hidden sm:flex gap-4 z-10 relative"
          justify="end"
          as={'ul'}
        >
          {menuItems.map((item, index) => (
            <NavbarItem key={index} className="relative neon-links">
              <Link
                href={{
                  pathname: item.link as any,
                }}
                className={headerLinks({})}
              >
                {item.name}
              </Link>
            </NavbarItem>
          ))}
          <li>
            <LocaleSwitcherSelect
              className="hidden sm:flex border"
              currentLocale={locale}
            />
          </li>
        </NavbarContent>
        <NavbarMenu>
          <LocaleSwitcherSelect
            currentLocale={locale}
            className="text-2xl font-bold border"
          />

          {menuItems.map((item, index) => (
            <NavbarMenuItem
              key={`${item}-${index}`}
              className="w-full px-2 py-4 text-2xl font-bold text-default-800 hover:text-green-600 transition-all"
            >
              <Link href={item.link as any}>{item.name}</Link>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
    </>
  );
};
export default Header;
